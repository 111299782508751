.ant-image-preview-img-wrapper::before {
  content: none !important;
}

.ant-image-preview-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-image-preview-img {
  width: 30%;
  max-width: 700px;
}
