.WatchTowerOrderInfo {
  .ant-descriptions-item-label {
    width: 124px;
  }
  .ant-descriptions-item-content {
    width: 150px;
  }
}

.OrderDetailTable {
  .ant-table-cell {
    vertical-align: top;
  }

  table:hover,
  tr:hover,
  thead:hover {
      background: none !important;
  }
  
  .ant-table-tbody > tr.ant-table-row:hover > td {
      background: none !important;
  }
}
