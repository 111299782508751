.ant-pro-table-search {
  background-color: #fafafa !important;
  margin: 0 24px 24px !important;
  border: 1px solid #f0f0f0;
}

.ant-select-multiple .ant-select-selection-item {
  color: #096dd9;
  background-color: #e6f7ff !important;
  border-color: #91d5ff !important;
}

.ant-select-multiple .ant-select-selection-item-content {
  text-transform: uppercase;
}

.row-highlight > .ant-table-cell-fix-left,
.row-highlight > .ant-table-cell-fix-right {
  background-color: #e6f7ff;
}

.row-highlight {
  background-color: #e6f7ff;
}

.ant-table table {
  border: 1px solid #f0f0f0;
}
