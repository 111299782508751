.MenuDrawer {
    .ant-drawer-body {
        padding: 0;
    }

    .ant-upload.ant-upload-select-picture-card {
        width: 100%;
        height: 100%;
    }
}

