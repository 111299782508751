.PromoUsage {
    .metricCard {
      border-radius: 6px;
      height: 100%;
      .ant-card-body {
        padding: 24px 16px;
      }
    }
    .tableCard {
      border-radius: 6px;
      table {
        border-radius: 0 !important;
      }
      .ant-pro-table-search-query-filter {
        margin: 0 !important;
        background-color: transparent !important;
        border: 0 !important;
        padding: 16px !important;
        padding-bottom: 0 !important;
        .ant-form-item {
          margin-bottom: 16px !important;
        }
      }
      .ant-table-cell {
        padding: 16px !important;
      }
      .ant-table-pagination.ant-pagination {
        margin: 16px !important;
      }
    }
    .ant-table.ant-table-middle {
      border-radius: 0 !important;
    }
  }
