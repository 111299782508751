.ModalDownloadReport {
  .subtitle {
    display: block;
    margin-bottom: 8px;
  }
  .downloadLink {
    display: none;
  }
}

