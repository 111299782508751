.discounted__menu__name {
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        height: auto;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        white-space: normal;
        line-height: 22px;
    }
}

