.MenuTemplateBodyContainer {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
        padding-inline: 24px;
    }
    > .ant-tabs-top {
        .ant-tabs-nav {
            margin-bottom: 24px;
        }
    }
}

