@import "~antd/dist/antd.less";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap");

html,
body {
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.ant-pro-table-search {
    border-radius: 6px;
}

.ant-table.ant-table-middle {
    border-radius: 6px !important;
    overflow: hidden !important;
}

.ant-notification-notice-success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
}

.ant-notification-notice-warning {
    background: #fff1f0;
    border: 1px solid #ffccc7;
}

.ant-notification-notice-info {
    background: #e6f7ff;
    border: 1px solid #91d5ff;
}

.ant-notification-notice-error {
    background-color: #fff1f0; /*Antd red 1*/
    border: 1px solid #ff4d4f; /*Antd red 5*/
}

.ResetSearchTableMargin {
    .ant-pro-table-search {
        margin-inline: 0 !important;
    }
}

@layer base {
    svg {
        display: inline;
        vertical-align: baseline;
    }
}
@layer utilities {
    .heading-h1-64 {
        font-family: Inter, sans-serif;
        font-weight: 800;
        font-size: 4rem;
        line-height: 5rem;
    }
    .heading-h2-48 {
        font-family: Inter, sans-serif;
        font-weight: 800;
        font-size: 3rem;
        line-height: 3.75rem;
    }
    .heading-h3-32 {
        font-family: Inter, sans-serif;
        font-weight: 800;
        font-size: 2rem;
        line-height: 2.5rem;
    }
    .heading-h4-24 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .heading-h5-20 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    .heading-h6-18 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.625rem;
    }
    .title-bold-20 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    .title-bold-18 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.625rem;
    }
    .title-bold-16 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .title-bold-14 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .subtitle-medium-20 {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    .subtitle-medium-18 {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.625rem;
    }
    .subtitle-medium-16 {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .subtitle-medium-14 {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .body-regular-20 {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    .body-regular-18 {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.625rem;
    }
    .body-regular-16 {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .body-regular-14 {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .caption-bold-12 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
    .caption-medium-12 {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
    .caption-regular-12 {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
    .footer-bold-10 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 0.625rem;
        line-height: 0.75rem;
    }
    .footer-medium-10 {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 0.75rem;
    }
    .label-bold-20 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.75rem;
        letter-spacing: 1.1.5625rem;
        text-transform: uppercase;
    }
    .label-bold-12 {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.125rem;
        letter-spacing: 0.078125rem;
        text-transform: uppercase;
    }
    .promo-medium-12 {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1.125rem;
        text-decoration-line: line-through;
    }
}
