.DebounceSelect {
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        height: auto;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        white-space: normal;
    }
    a.ant-typography-ellipsis-single-line, span.ant-typography-ellipsis-single-line {
        white-space: normal;
    }
}

