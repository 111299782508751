.ModalChangeReason {
  .subtitle {
    display: block;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.4);
  }
  .dropdown {
    margin-bottom: 16px;
  }
  .dropdownTitle {
    display: block;
    margin-bottom: 8px;
  }
  .dropdownButton {
    width: 100%;
    display: flex;
    margin-top: 8px;
    align-items: center;
    justify-content: space-between;
    span[role="img"] {
      height: 14px;
      font-size: 14px;
    }
  }
}

